* {
  padding: 0;
  margin: 0;
}

.desktopversioncart {
  display: block;
}

.mobileversioncart {
  display: none;
}

@media screen and (min-width: 280px) and (max-width: 768px) {
  .desktopversioncart {
    display: none;
  }

  .mobileversioncart {
    display: block;
  }

  .MainPartOfCartPage {
    /* border: 2px solid red; */
    width: 100%;
    background-color: #f2f2f2;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .NavofCartpage {
    border: 2px solid #fff;
    height: 4.4rem;
    top: 0px;
    position: fixed;
    width: 100%;
    /* border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem; */
    background-color: #fff;
    flex-direction: column;
    display: flex;
    padding: 0.2rem;
  }

  .navpart1 {
    flex-direction: row;
    /* border: 1px solid; */
    justify-content: flex-start;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .iconn {
    /* border: 1px solid; */
    font-size: 1rem;
    color: #000;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .iconnn {
    font-weight: 700;
    /* align-items: center; */
  }

  .yourcarttextt {
    color: #000;
    font-size: 1rem;
    font-weight: bold;
  }

  .navpart2 {
    flex-direction: row;
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* padding: 0.5rem; */
  }

  .navpart2 > span {
    /* border: 1px solid #ebcfc7; */
    padding: 0.4rem;
    display: flex;
    gap: 0.25rem;
    border-radius: 0.6rem;
    /* background-color: #ebcfc7; */
    font-size: 0.7rem;
    align-items: center;
    /* justify-content: center; */
  }

  .savedmoney {
    border: 2px solid #ebcfc7;
    border-radius: 0.5rem;
    font-size: 0.65rem;
    padding: 0.1rem;
    background-color: #ebcfc7;
    /* background-color: #cefbe4; */
    color: #7e412e;
    font-weight: 500;
    gap: 0.25rem;
    display: flex;
    align-items: center;
  }

  .saved_money_con {
    height: 2rem;
    width: 95%;
    background-color: #ebcfc7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  }

  .for_free_deliveryyyy_text {
    color: #7e412e;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .bold_selling_priceeee {
    color: #7e412e;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .to_unlock_freee {
    color: #7e412e;
    font-size: 0.7rem;
  }

  .add_itemsssssss {
    color: #7e412e;
    font-size: 0.7rem;
  }

  #boldtexttt {
    font-size: 0.7rem;
  }

  .circleIconn {
    font-size: 1.15rem;
    color: #7e412e;
  }

  .colorchange {
    color: #7e412e;
    font-weight: 600;
  }

  .ContainerPartofCartPage {
    margin-top: 3.7rem;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #f2f2f2;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.8rem;
  }

  .ContainerPartofCartPage::-webkit-scrollbar {
    display: none;
  }

  .container1 {
    /* border: 1px solid blue; */
    width: 100%;
    /* height: 45vh; */
    border-radius: 0.8rem;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
  }

  .Containerpart1 {
    /* border: 1px solid; */
    /* padding: 0.6rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
  }

  .clockIconn {
    /* border: 1px solid; */
    color: #fff;
    font-size: 1.2rem;
    /* padding: 0.3rem; */
  }

  .clockContainer {
    border: 2px solid #4dffc3;
    padding: 0.2rem;
    width: 1.8rem;
    display: flex;
    border-radius: 0.4rem;
    background-color: #4dffc3;
    /* background-color:#66ffb3; */
    /* color: #7e412e; */
  }

  .deliveryText {
    font-size: 0.9rem;
    font-weight: 700;
  }

  .Containerpart2 {
    /* border: 1px solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
  }

  .Containerpart3 {
    /* border: 1px solid; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* height: 4.5rem;   */
  }

  .dottedline {
    border: 1px solid;
    width: 100%;
    margin-bottom: 0.5rem;
    border-style: dashed;
    color: #eaeae8;
  }

  .textContainerrr {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .missText {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .addmorebtn {
    border: 1px solid #000;
    border-radius: 0.2rem;
    font-size: 0.6rem;
    font-weight: 600;
    padding: 0.26rem;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
  }

  .addmorebtn > span {
    display: flex;
    align-items: center;
  }

  .addIconnn {
    font-size: 1rem;
  }

  .imageAndName {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    width: 68%;
  }

  .imgContainer {
    /* border: 2px solid; */
    height: 7.5vh;
    width: 22%;
    border-radius: 0.2rem;
  }

  .imagee {
    /* height: 10vh; */
    height: 7.5vh;
    width: 100%;
    border-radius: 0.2rem;
  }

  .aboutImgText {
    /* border: 1px solid green; */
    width: 9rem;
    display: flex;
    flex-direction: column;
    /* border: 2px solid; */
    height: 3rem;
  }

  .aboutImgText > h6 {
    font-size: 0.8rem;
    /* border: 1px solid red; */
  }

  .aboutImgText > p {
    font-size: 0.6rem;
    color: #c2c2a3;
    /* border: 1px solid blue; */
    margin-top: -0.4rem;
  }

  .addorRemove {
    border: 1px solid #7e412e;
    width: 3.6rem;
    display: flex;
    justify-content: space-around;
    border-radius: 0.3rem;
    align-items: center;
    background: rgb(126, 65, 46);
    background: linear-gradient(
      106deg,
      rgba(126, 65, 46, 1) 0%,
      rgba(187, 128, 90, 1) 78%
    );
    padding: 0.2rem;
  }

  .addorRemove > span {
    font-size: 0.8rem;
    color: #000;
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .symb {
    font-size: 0.9rem;
    font-weight: 700;
  }

  #coloring {
    color: #fff;
    font-weight: 600;
  }

  .Pricepart {
    /* border: 1px solid; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 2rem; */
    /* justify-content: center; */
  }

  .Pricepart > h6 {
    font-size: 0.8rem;
    margin-top: 0.8rem;
    color: #e69900;
  }

  .Pricepart > p {
    font-size: 0.6rem;
    text-decoration: line-through;
    color: #c2c2a3;
    margin-top: -0.5rem;
  }

  .container2 {
    /* border: 1px solid blue; */
    width: 100%;
    /* height: 20vh; */
    border-radius: 0.8rem;
    background-color: #ffffb3;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    /* box-shadow: #000; */
  }

  .cont2part1 {
    /* border: 1px solid red; */
    /* padding: 0.6rem; */
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.4rem;
    padding: 0.4rem;
    border-bottom: 1px solid #aaaa55;
    border-style: dashed;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .cont2part2 {
    /* border: 2px solid red; */
    padding: 0.4rem;
    display: flex;
  }

  .cont2part2 > span {
    font-size: 0.7rem;
    display: flex;
    gap: 0.3rem;
  }

  .goldcoloring {
    color: #e69900;
  }

  .cont2part1 > h6 {
    margin: 0;
    /* Reset default margins on h6 */
    display: flex;
    align-items: center;
    /* Vertically center text inside h6 */
    font-size: 0.8rem;
    color: #e69900;
  }

  .cont2part1 > span {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .passIcon {
    color: #e69900;
  }

  .container3 {
    /* border: 1px solid blue; */
    width: 100%;
    /* height: 33vh; */
    border-radius: 0.8rem;
    /* background-color: #ffffb3; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* padding: 20px 0px; */
  }

  .conatiner333 {
    margin-top: 3rem;
  }

  .cont3subPart {
    /* border: 1px solid red; */
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1.4px solid grey;
    border-style: dashed;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .cont3subPartt {
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
  }

  .tipText {
    display: flex;
    flex-direction: column;
  }

  .tipText > span {
    font-size: 0.7rem;
  }

  .ArrowIcon {
    display: flex;
    justify-content: flex-end;
  }

  .moneyIconAndTipText {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
  }

  .Savinggs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
  }

  .priceeee {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 0.2rem; */
  }

  .priceeee > span {
    font-size: 0.6rem;
  }

  .savingmoneyyy {
    border: 1px solid #ebcfc7;
    border-radius: 0.8rem;
    font-weight: 500;
    padding: 0.1rem;
    color: #7e412e;
    background-color: #ebcfc7;
    width: 5rem;
    text-align: center;
  }

  .footerPartofCartPage {
    border: 2px solid #fff;
    height: 10vh;
    width: 100%;
    bottom: 0;
    z-index: 999;
    padding: 0.8rem;
    background-color: #fff;
    position: fixed;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .container-fluid-lg {
    padding: unset;
  }

  .btnContainerr {
    /* border: 2px solid #7e412e; */
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    /* background-color:#ff0040; */
    background: rgb(126, 65, 46);
    background: linear-gradient(
      106deg,
      rgba(126, 65, 46, 1) 0%,
      rgba(187, 128, 90, 1) 78%
    );
  }

  .btnContainerr > button {
    font-size: 0.8rem;
    padding: 0.25rem;
    border: none;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
  }
}
