@import url("https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=Barrio&family=Charm:wght@400;700&family=Chewy&family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lavishly+Yours&family=Lobster&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 280px) and (max-width: 768px) {
  .MainofSplashScreen {
    top: 0px;
    height: 100vh;
    width: 100%;
    /* background-image: url(../../assets/Images/BackgroundImage6.jpg); */
    background-image: url(../../../public/assets/images/splash_Screen2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    gap: 1rem;
  }

  /* .numberr::placeholder{
    color:#808080;
  } */
  .SubpartOfSplash {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* border:2px solid red; */
    height: 100%;
  }

  .logoContainer {
    height: 12rem;
    width: 10rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .logoImgg {
    height: 5rem;
    width: 8rem;
  }

  .titlee {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .phonenumberpart {
    border: 1px solid #fff;
    width: 96%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
    border-radius: 0.5rem;
  }

  .indianNum {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid;
    padding: 0.4rem;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-color: #fff;
  }

  .Inputpart {
    /* border: 1px solid #000; */
    height: 3rem;
    border-radius: 0.6rem;
    width: 100%;
    border: none;
  }

  .indiaaa {
    font-size: 0.9rem;
    font-weight: 700;
  }

  .flagImgg {
    width: 2rem;
  }

  .numberr {
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
    background-color: transparent;
    border: none;
    font-family: "Poppins", serif;
    /* font-family: "Charm", cursive; */
    font-weight: 500;
  }

  .numberr::placeholder {
    padding-left: 0.5rem;
    color: #e9fcf4;
    font-size: 0.9rem;
    font-weight: 500;
    align-items: center;
  }

  /* .enternumber{
        border: 1px solid green;
     } */
  .enternumber > h5 {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    align-items: center;
    font-family: "Lobster", sans-serif;
    /* font-family: "Charm", cursive; */
    font-weight: 500;
    font-style: normal;
  }

  .enternumber > span {
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-family: "Lobster", sans-serif;
    /* font-family: "Charm", cursive; */
    font-weight: 500;
  }

  .downPartofSplash {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    /* justify-content: center; */
    border-radius: 1.3rem;
    background-color: #7e412e;
    border-color: #d3f8e9;
  }

  .downPartofSplash > button {
    padding: 0.7rem;
    background-color: transparent;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-weight: bold;
    color: #fff;
    font-size: 0.9rem;
    font-family: "Poppins", serif;
    /* font-family: "Charm", cursive; */
    font-weight: 500;
  }

  .accpetingText {
    font-size: 0.65rem;
    font-weight: 500;
    color: #fff;
    /* font-family: "Lobster", sans-serif; */
    font-family: "Charm", cursive;
    font-weight: 500;
  }

  .colorrr {
    color: #cc0052;
  }
  .textbot_l {
    color: #cdb599;
  }
}
