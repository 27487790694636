/* * {
    margin: 0;
    padding: 0;
}

.register_mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .register_mobile {
        display: block;
    }

    .inside_register_page {
        min-height: 100vh;
        width: 100%;
        background-image: url('../../public/assets/images/registerrrrrscrreen.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }

    .center_containerrrr {
        height: 8rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .inside_image {
        height: 5.5rem;
        width: 4.5rem;
    }

    .container_sub_divv {
        width: 65%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      
        
    }

    .a_whole_p {
        color: #ffff;
        font-size: 1.1rem;
        text-align: center;
        font-weight: bold;
    }

    .create_acccount_register {
        height: 18rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .inside_containerr_register {
        padding-top: 2rem;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }

    .cre_acccc_reg {
        width: 92%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

    }

    .create_acccccc {
        font-size: 1.8rem;
        color: #000;
        font-weight: 600;
        font-family: 'Poppins';
    }

    .input_containerrrrrr_ {
        width: 100%;
        height: 2.1rem;
        background-color: yellow;
    }

    .input_insideee {
        width: 100%;
        height: 100%;
        border: none;
        border-bottom: 2px solid #cccc;
    }

    .input_insideee::placeholder {
        color: #d9e0d9;
        font-family: "Poppins";
    }

    .div_containerrrrrr {
        width: 100%;
        height: 3rem;
    }

    .registerr_text {
        width: 100%;
        background-color: #0da487;
        height: 100%;
        color: #ffff;
        font-size: 1.1rem;
        border: 1px solid #0da487;
        border-radius: 30px;
    }

} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.register_mobile {
    display: none;
}

.register_for_mobile_only {
    display: block !important;
}

@media screen and (max-width: 768px) {
    .register_mobile {
        display: block;
        overflow: hidden;
    }

    .register_for_mobile_only {
        display: none !important;
    }

    .inside_register_page {
        min-height: 100vh;
        width: 100%;
        background-image: url('../../public/assets/images/registerrrrrscrreen.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 1rem;
    }

    .center_containerrrr {
        height: 11.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        justify-content: center;
        flex-direction: column;
    }

    .inside_image {
        height: 6rem;
        width: 6rem;
    }

    .container_sub_divv {
        width: 80% !important;
        text-align: center;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .a_whole_p {
        color: #ffff;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .to_make_centerrrr {
        margin-top: 5rem;
        height: 20rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .create_acccount_register {

        width: 100%;
        /* height:; */
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .cre_acccc_reg {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .create_acccccc {
        font-size: 1.6rem;
        color: #000;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    }

    .input_containerrrrrr_ {
        width: 100%;
        height: 3rem;
        background-color: transparent;
    }

    .input_insideee {
        width: 100%;
        height: 100%;
        border: none;
        border-bottom: 2px solid #cccc;
        background-color: transparent;
        font-size: 1rem;
        padding: 0.5rem;
        color: #000;
    }

    .input_insideee::placeholder {
        color: #d9e0d9;
        font-family: "Poppins", sans-serif;
    }

    .div_containerrrrrr {
        margin-top: 1rem;
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
    }

    .registerr_text {
        width: 100%;
        background-color: #7e412e;
        height: 100%;
        color: #fff;
        font-size: 1.1rem;
        border: 1px solid #7e412e;
        border-radius: 30px;
        cursor: pointer;
    }
}