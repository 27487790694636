.fill_cart_code {
  display: none;
}

.desktopversioncart {
  display: block;
}

.mobileversioncart {
  display: none;
}

@media screen and (max-width: 500px) {
  .desktopversioncart {
    display: none;
  }

  .mobileversioncart {
    display: block;
  }

  /*  code for checkout mobileversion */
  .check_out {
    display: block;
  }

  .navcontent {
    top: 0px;
    position: fixed;
    width: 100%;
    height: 3.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: #fff;
    padding: 0.8rem;
  }

  .sub_nav_price {
    display: flex;
    justify-content: space-between;
  }

  .img_sub {
    height: 40px;
    width: 100px;
  }

  .sub_imgaes {
    display: flex;
    gap: 10px;
  }

  .pricessmoney {
    display: flex;
    height: 2.3rem;
    align-items: flex-end;
    flex-direction: column;
  }

  .mrp_price_check {
    font-size: 0.8rem;
    color: #b3b3b3;
    text-decoration-line: line-through;
  }

  .selling_price_check {
    font-size: 1rem;
    color: #000;
    font-weight: bold;
  }

  .nav_bar_content_down {
    height: 2rem;
    width: 100%;
    margin-top: 3.5rem;
    background-color: #ff6600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bold_text {
    margin-top: 0.8rem;
    color: #ffff;
    text-align: center;
    /* border: 1px solid black; */
    font-weight: 500;
  }

  .weight_bold_text {
    color: #ffff;
    font-weight: bold;
  }

  .total_check_out {
    height: 35rem;
    width: 100%;
    background-color: #f2f2f2;
  }

  .name_products {
    font-weight: 600;
    font-size: 0.8rem;
    font-family: "Poppins";
  }

  .order_summary_cart {
    /* height: 23rem; */
    /* padding-top: 1rem; */
    margin-top: 4.3rem;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total_check_out::-webkit-scrollbar {
    display: none;
  }

  .sub_order_sum {
    width: 98%;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 20px;
  }

  .sub_orderrrrr {
    width: 100%;
    padding: 1rem;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order_pageee {
    height: 2rem;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .order_text {
    color: #000;
    font-weight: 600;
  }

  .number_items {
    color: #000;
    font-weight: 600;
  }

  .two_order_pagee {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .cart_itemsssss {
    width: 100%;
  }

  .sing_carttt {
    height: 6rem;
    width: 100%;
    display: flex;
    padding: 0.8rem;
    justify-content: space-between;
  }

  .sub_img_conta {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .containerrrr_cart {
    display: flex;
    align-items: center;
    width: 5rem;
    height: 1.5rem;
    padding: 5px;
    border-radius: 5px;
    justify-content: space-between;
    border: 1px solid #f2f2f2;
  }

  .style_incredecre {
    color: #8c8c8c;
    font-size: 0.6rem;
  }

  .sunb_content {
    color: #4d4d4d;
    font-weight: 600;
    font-size: 0.8rem;
    padding-top: 7px;
  }

  .price_number {
    color: #000;
    font-size: 0.8rem;
  }

  .delte_iconn {
    margin-top: 0.2rem;
    height: 1.8rem;
    width: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid #f2f2f2;
  }

  .pricess_cart {
    display: flex;
    flex-direction: column;
  }

  .actual_price {
    color: #bfbfbf;
    font-size: 0.7rem;
    text-decoration-line: line-through;
  }

  .selling_price_dis {
    color: #000;
    font-size: 0.8rem;
    font-weight: bold;
    font-family: "Poppins";
  }

  .img_containnerr {
    height: 4.3rem;
    width: 4.8rem;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img_cart_img {
    height: 4rem;
    width: 4rem;
  }

  .sub_total_price {
    height: 7.5rem;
    width: 100%;
    font-family: "Poppins";
  }

  .inner_sub_tottal {
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
  }

  .total_summmty {
    display: flex;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    justify-content: space-between;
  }

  .total_Spell {
    color: #000;
    font-size: 0.9rem;
    font-family: "Poppins";
  }

  .discount_sub {
    color: #009933;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .total_price {
    color: #000;
    font-weight: bold;
  }

  .inner_sub_priii {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sub_total_ {
    font-size: 0.9rem;
    color: #000;
    font-weight: 400;
  }

  .pricreee {
    color: #000;
    font-weight: 600;
    font-family: "Poppins";
  }

  .coupon_content {
    margin-top: 0.5rem;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_coupon {
    width: 96%;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
  }

  .delteeeee {
    color: #bfbfbf;
  }

  .apply_content {
    color: #009933;
    font-weight: bold;
    text-decoration: underline;
  }

  .green_content {
    color: #009933;
    font-weight: 600;
    font-size: 0.8rem;
  }

  .toflex_coupom {
    display: flex;
    flex-direction: column;
  }

  .two_couiuu {
    color: #000;
    font-weight: 200;
    font-size: 0.7rem;
  }

  .hextshss {
    color: #009933;
  }

  .sub_coupon {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  .inner_coupon {
    display: flex;
    gap: 10px;
  }

  .add_addressss {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bnt_addre {
    width: 95%;
    height: 2.8rem;
    color: #fff;
    border-radius: 10px;
    background: rgb(126, 65, 46);
    background: linear-gradient(
      106deg,
      rgba(126, 65, 46, 1) 0%,
      rgba(187, 128, 90, 1) 78%
    );
    font-family: "Popines";
    font-size: 1rem;
  }

  .down_contemt_of_add {
    height: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.1rem;
  }

  .keep_mmee {
    font-size: 1rem;
  }

  /* for drawer otp */
  .constsiner_sty {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    gap: 10px;
    font-size: 20px;
    border: "none";
  }

  .inputStylesssss {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    margin: 0 5px;
    border: 1px solid #7e412e;
    transition: border-color 0.3s;
  }

  .inputStylesssss:focus {
    outline: none;
    border-color: #7e412e;
    box-shadow: 0 0 5px #7e412e;
  }

  .colorforthat {
    width: 100%;
    height: 1.8rem;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    padding-top: 0.5rem;
    align-items: center;
    justify-content: center;
    background-color: #ff6600;
  }

  .bold_text1 {
    text-align: center;
    color: #fff;
    padding-top: 0.6rem;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .weight_bold_text1 {
    text-align: center;
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
  }

  :where(.css-dev-only-do-not-override-5wsri9).ant-drawer .ant-drawer-body {
    padding: 0 !important;
  }

  .mobile_number_Containe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 0.1rem;
    border-radius: 8px;
    height: 2.4rem;
    width: 100%;
  }

  .flag_Connn {
    display: flex;
    align-items: center;
  }

  .flgicom {
    width: 24px;
    height: 16px;
    margin-right: 8px;
  }

  .down_Arroe {
    margin-right: 8px;
    cursor: pointer;
  }

  .phone_numberrrr_input_con {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .phone_numberrrr_input {
    width: 100%;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 4px;
  }

  .personal_detailsss {
    color: #000;
    font-size: 0.8rem;
  }

  .personal-details-container {
    height: 22.5rem;
    /* height: auto; */
    width: 100%;
    border-top: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-top: 20px;
  }

  .personal-details-containersss {
    height: 22rem;
    width: 100%;
    /* border-top: 1px solid #ccc; */
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-top: 20px;
  }

  .container-header {
    position: absolute;
    top: -12px;
    left: 20px;
    background-color: white;
    padding: 0 10px;
  }

  .container-headersss {
    position: absolute;
    top: -12px;
    left: 20px;
    background-color: white;
    padding: 0 10px;
  }

  .for_flexss_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .textarea-containerrr {
    border: 1px solid #ccc;
    border-radius: 8px;
    /* padding: 20px; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .textarea-containerrr textarea {
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    resize: vertical;
  }

  .saved_Address_details {
    height: 9rem;
    width: 100%;
    border-top: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-top: 10px;
  }

  .center_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .verified_cardddd {
    /* margin-top: 0.8rem; */
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .verify_btn {
    height: 2.7rem;
    width: 80%;
    background: linear-gradient(
      106deg,
      rgba(126, 65, 46, 1) 0%,
      rgba(187, 128, 90, 1) 78%
    );
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    border: none;
  }

  .sub_content_save {
    margin-top: 0.5rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .save_and_Continuous_btn {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .for_sabe {
    width: 100%;
    height: 3rem;
    background: rgb(126, 65, 46);
    background: linear-gradient(
      106deg,
      rgba(126, 65, 46, 1) 0%,
      rgba(187, 128, 90, 1) 78%
    );
    color: #ffff;
    font-weight: bold;
    /* border: 1px solid #7e412e; */
    border-radius: 10px;
    border: none;
  }

  .chec_address {
    margin-top: 1rem;
    display: flex;
    gap: 5px;
  }

  .home_content {
    height: 2.6rem;
    width: 6rem;
    border: 1px solid #7e412e;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    background-color: #ffff;
  }

  .home_content_border {
    height: 2.6rem;
    width: 6rem;
    border: 1px solid #ccc;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    background-color: #ffff;
  }

  .click_icon {
    color: #0da487;
  }

  .addres_De {
    font-size: 0.8rem;
    text-align: center;
    color: #000;
  }

  .sub_Saved_Add {
    position: absolute;
    top: -12px;
    left: 20px;
    background-color: white;
    padding: 0 10px;
  }

  .save_text {
    font-weight: 500;
    font-size: 0.6rem;
    text-transform: uppercase;
    color: #333;
  }

  .extarea-containerrr textarea::placeholder {
    font-size: 12px;
    color: #999;
  }

  .for_personal {
    font-weight: 500;
    font-size: 0.6rem;
    text-transform: uppercase;
    color: #333;
  }

  .style_for_check {
    color: #0e947a;
  }

  .phone_nummmm {
    color: #000;
    font-size: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .for_labels {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .for_flex_con {
    padding-top: 0.2rem;
    display: flex;
    gap: 5px;
  }

  .for_keepsss {
    font-size: 0.6rem;
    color: #000;
  }

  .input-containerrrrd {
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 2.2rem;
    width: 100%;
    display: flex;
    /* background-color: red; */
    flex-direction: column;
  }

  .input-containerrrrd input {
    height: 100%;
    border: 1px solid #fff;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .input-containerrrrd_loginnn {
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 2.2rem;
    width: 100%;
    display: flex;
    /* background-color: red; */
    flex-direction: row;
    align-items: center;
    padding: 0.3rem;
  }

  .input-containerrrrd_loginnn input {
    height: 100%;
    border: 1px solid #fff;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .input-container input::placeholder {
    font-size: 0.6rem;
    color: #999;
  }

  /* for subCATEGORY Code */

  .catsub_mobile_version {
    display: block;
  }

  .cat_navbar_header {
    height: 3.2rem;
    width: 100%;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    background-color: #ffff;
    top: 0;
    position: fixed;
    border: unset;
    padding-left: 5px;
  }

  .sub_content_navvv {
    display: flex;
    justify-content: space-between;
    padding: 0.8rem;
  }

  .inner_cat_nav {
    display: flex;
    gap: 10px;
  }

  .serch_iconwee {
    font-size: 1.3rem;
  }

  .arrow_to_back {
    font-size: 2.1rem !important;
  }

  .serach_sss {
    font-size: 1.8rem !important;
  }

  .categoru_name {
    font-size: 1rem;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: bold;
  }

  .number_offf_items {
    font-size: 0.8rem;
  }

  .cat_subbb {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  #payment_designnn {
    height: 10rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #toflexxx_check_outttt {
    background-color: blue;
    display: flex !important;
    flex-direction: column !important;
  }
}
